.contact_us_2 * {
    font-family: Nunito, sans-serif;
    /* margin-top: 100px; */
  }
  .mt-top{
    margin-top: 105px;
  }
  .contact_us_2 .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 25px;
  }
  
  .contact_us_2 .responsive-cell-block {
    min-height: 75px;
  }
  
  .contact_us_2 input:focus {
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
  }
  
  .contact_us_2 .container-block {
    min-height: 75px;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: block;
  }
  
  .contact_us_2 .submit-btn:hover {
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: #0381fe;
  }
  
  .contact_us_2 .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .contact_us_2 .responsive-container-block.big-container {
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    position: relative;
    height: auto;
  }
  
  .contact_us_2 .responsive-container-block.container {
    position: static;
    min-height: 75px;
    flex-direction: column;
    z-index: 2;
    max-width: 800px;
    margin-top: 120px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
  }
  
  .contact_us_2 .container-block.form-wrapper {
    background-color: white;
    max-width: 799px;
    text-align: center;
    padding-top: 50px;
    padding-right: 40px;
    padding-bottom: 50px;
    padding-left: 40px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 7px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
  }
  
  .contact_us_2 .text-blk.contactus-head {
    font-size: 36px;
    line-height: 50px;
    font-weight: 900;
  }
  
  .contact_us_2 .text-blk.contactus-subhead {
    color: #9c9c9c;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
  }
  
  .contact_us_2 .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 26px;
    margin-left: 0px;
    min-height: 50px;
  }
  
  .contact_us_2 .input {
    width: 96%;
    height: 50px;
    padding-top: 1px;
    padding-right: 15px;
    padding-bottom: 1px;
    padding-left: 15px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #eeeeee;
    border-right-color: #eeeeee;
    border-bottom-color: #eeeeee;
    border-left-color: #eeeeee;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    font-size: 16px;
    color: black;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .contact_us_2 .textinput {
    width: 98%;
    min-height: 150px;
    padding-top: 20px;
    padding-right: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #eeeeee;
    border-right-color: #eeeeee;
    border-bottom-color: #eeeeee;
    border-left-color: #eeeeee;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    font-size: 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .contact_us_2 .submit-btn {
    width: 98%;
    background-color: #03a9f4;
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    color: white;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: outset;
    border-right-style: outset;
    border-bottom-style: outset;
    border-left-style: outset;
    border-top-color: #767676;
    border-right-color: #767676;
    border-bottom-color: #767676;
    border-left-color: #767676;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;
  }
  
  .contact_us_2 .form-box {
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
  }
  
  .contact_us_2 .social-media-links {
    width: 250px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
  }
  
  .contact_us_2 .link-img {
    width: 30px;
    height: 30px;
  }
  
  .contact_us_2 .text-blk.input-title {
    text-align: left;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
    font-size: 14px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    color: #9c9c9c;
  }
  
  .contact_us_2 ::placeholder {
    color: #dadada;
  }
  
  .contact_us_2 .blueBG {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    height: 300px;
    background-color: #03a9f4;
  }
  
  @media (max-width: 768px) {
    .contact_us_2 .submit-btn {
      width: 100%;
    }
  
    .contact_us_2 .input {
      width: 100%;
    }
  
    .contact_us_2 .textinput {
      width: 100%;
    }
  
    .contact_us_2 .container-block.form-wrapper {
      margin-top: 80px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_2 .text-blk.input-title {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .contact_us_2 .responsive-container-block.container {
      margin-top: 80px;
      margin-right: 0px;
      margin-bottom: 50px;
      margin-left: 0px;
    }
  
    .contact_us_2 .container-block.form-wrapper {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_2 .text-blk.contactus-head {
      font-size: 30px;
      line-height: 40px;
    }
  }
  
  @media (max-width: 500px) {
    .contact_us_2 .container-block.form-wrapper {
      padding-top: 50px;
      padding-right: 15px;
      padding-bottom: 50px;
      padding-left: 15px;
    }
  
    .contact_us_2 .container-block.form-wrapper {
      margin-top: 60px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_2 .responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6 {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 15px;
      margin-left: 0px;
    }
  
    .contact_us_2 .responsive-container-block {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 35px;
      margin-left: 0px;
    }
  
    .contact_us_2 .text-blk.input-title {
      font-size: 12px;
    }
  
    .contact_us_2 .responsive-container-block.container {
      margin-top: 50px;
      margin-right: 0px;
      margin-bottom: 50px;
      margin-left: 0px;
    }
  
    .contact_us_2 .container-block.form-wrapper {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_2 .responsive-container-block.big-container {
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
  
    .contact_us_2 .text-blk.contactus-head {
      font-size: 26px;
      line-height: 34px;
    }
  
    .contact_us_2 .input {
      height: 45px;
    }
  }