/* Navbar wrapper that is fixed at the top */
.navbar-wrapper {
  position: fixed; /* Fixed position so it stays at the top */
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure it stays above other content */
  background-color: transparent; /* Default: transparent background */
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  padding: 10px 0; /* Add padding for the navbar */
  height: 100px;
}

/* When scrolled, change the background to white and add shadow */
.navbar-wrapper.scrolled {
  background-color: white; /* Background white when scrolling */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

/* Container for navbar elements */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-link {
  font-weight: 600;
  margin: 0 12px;
  color: black;
}

/* Navbar logo */
.navbar-logo {
  height: 40px;
}

/* Profile icon and toggler button on small screens */
.d-lg-none {
  display: flex;
  align-items: center;
}

.profile-icon {
  font-size: 25px;
  margin-right: 20px;
}

.navbar-toggler {
  font-size: 20px;
}
/* When the navbar toggler is open, change nav links background to red */
.navbar-content.show {
  ul {
    background-color: white; /* Change background color to red */

  }

  
}

/* Media query for small screens (handling the toggler behavior) */
@media (max-width: 992px) {
  .navbar-content {
    background-color: transparent; /* Ensure it's transparent by default */
  }

  /* Adjust for when the toggler is clicked */
 
}

/* Adjustments for small screens */
@media (max-width: 992px) {
  .navbar-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .navbar-content {
    width: 100%;
    padding: 10px 0;
  }

  .navbar-toggler {
    font-size: 18px;
  }

  .profile-icon {
    font-size: 18px;
  }
}

/* Center content on large screens */
@media (min-width: 993px) {
  .navbar-content {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .d-lg-none {
    display: none;
  }
}
