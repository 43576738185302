/* Container for image and text to center both */
.hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height of the viewport */
    text-align: center; /* Center text */
  }
  .section-hero{
    margin-top: 60px;
  }
  .h1-hero {
    font-size: 80px;
    margin-top: 20px;
    line-height: 70px;
    font-weight: 500;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  
  .img-hero {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 20px 0; /* Add space around the image */
  
    /* Small screens */
    @media (max-width: 576px) {
      max-width: 250px;
      height: auto;
    }
  
    /* Medium screens (tablets) */
    @media (min-width: 577px) and (max-width: 768px) {
      max-width: 300px;
      height: auto;
    }
  
    /* Larger screens */
    @media (min-width: 769px) and (max-width: 992px) {
      max-width: 350px;
      height: auto;
    }
  
    /* Extra-large screens */
    @media (min-width: 993px) {
      max-width: 400px;
      height: auto;
    }
  }
  
  .h5-hero {
    color: blueviolet;
    margin-top: 30px;
  }
  
  .p-hero {
    font-weight: 400;
    line-height: 25px;
  }
  .btn-hero{
    background-color: blue;
    color: white;
    border-radius: 5px;
    padding: 10px 25px;
    border:  1px solid white;
    
  }
  .btn-hero:hover{
    background-color: rgb(112, 112, 241);

  }
  .btn-hero2{
    margin-left: 20px;
    background-color: white;
    color: blue;
    border-radius: 5px;
    padding: 10px 25px;
    border:   1px solid blue;
  }
  .btn-hero2:hover{
    background-color: blue;
    color: white;
    border:  1px ;
  }