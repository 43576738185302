.footer-07 {
  background: #f8f9fa;
  padding: 40px 0;

  .container {
    .row {
      .col-md-4 {
        display: flex;
        align-items: center;

        .footer-heading {
          .logo {
            font-size: 2rem;
            text-decoration: none;
            color: white;
            &:hover {
              color: #007bff;
            }
          }
        }

        .menu {
          a {
            margin: 0 10px;
            color: white;
            text-decoration: none;
            &:hover {
              color: #007bff;
            }
          }
        }

        .ftco-footer-social {
          list-style: none;
          display: flex;
          justify-content: flex-end;
          li {
            margin: 0 10px;
            a {
              font-size: 1.5rem;
              color: white;
              &:hover {
                color: #007bff;
              }
            }
          }
        }
      }
    }
  }
}
