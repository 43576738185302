/* Default styles (for larger screens) */
.img-col {
  order: 1; /* Image comes first */
}

.text-col {
  order: 2; /* Text comes second */
}

.img-bio {
  width: 500px;
  height: auto;
  margin: 20px 0;
}

/* Small screens (mobile) */
@media (max-width: 576px) {
  .img-col {
    order: 2; /* Image comes after text */
  }

  .text-col {
    order: 1; /* Text comes first */
  }

  /* Adjust image size for small screens */
  .img-bio {
    max-width: 100%;
    height: auto;
  }
}

/* Medium screens (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
  .img-bio {
    max-width: 300px;
    height: auto;
  }
}

/* Larger screens */
@media (min-width: 769px) and (max-width: 992px) {
  .img-bio {
    max-width: 350px;
    height: auto;
  }
}

/* Extra-large screens */
@media (min-width: 993px) {
  .img-bio {
    width: 100%;
    height: auto;
  }
}
