.animated-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.animated-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card-rating {
  color: #FFD700; /* Gold color for stars */
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

@media (max-width: 576px) {
  .animated-card {
    margin-bottom: 20px;
  }
}
