$left-color:  #242e4d;
$right-color: #897e79;
$green-dark:  #35c3c1;
$green-light: #00d6b7;
$gray:        #8f8f8f;
$gray-light:  #f5f6f8;

* {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body, html {
  height: 100%;
  margin: 0;
}

.section-login{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  background-color: $gray-light;
}

.login-form {
  width: 300px;
  height: 300px;
  padding: 3em; 
  position: relative;
  background: rgba(black, .15);
  border-radius: 10px; // Optional for rounded corners
 
  &:before {
    content: '';
    position: absolute;
    top: -2px; left: 0;
    height: 2px; width: 100%;
    background: linear-gradient(
      to right,
      $green-dark,
      $green-light
    );    
  }
  
  @media screen and (min-width: 600px) {
    // width: 35vw; // Larger width
    // max-width: 25em; // Increase the max-width for bigger size
  }
}

.flex-row {
  display: flex;
  margin-bottom: 1.5em; // Increase margin for spacing
}

.lf--label {
  width: 3em; // Increase label width
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray-light;
  cursor: pointer;
}

.lf--input {
  flex: 1;
  padding: 1.5em; // Increase padding for bigger input fields
  border: 0;
  color: $gray;
  font-size: 1.25rem; // Increase font size
  
  &:focus {
    outline: none;
    transition: transform .15s ease;
    transform: scale(1.05); // Subtle scale on focus
  }
}

.lf--submit {
  display: block;
  padding: 1.5em; // Increase padding for a larger button
  width: 100%;
  background: linear-gradient(
    to right,
    $green-dark,
    $green-light
  );
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 1em; // Bigger font size
  font-weight: 600;
  text-shadow: 0 1px 0 rgba(black, .2);
  
  &:focus {
    outline: none;
    transition: transform .15s ease;
    transform: scale(1.05);
  }
}

.lf--forgot {
  margin-top: 1.5em; // Adjust margin for better spacing
  color: $green-light;
  font-size: 0.75em; // Increase font size
  text-align: center;
  position: relative;
}

::placeholder {
  color: $gray;
}
