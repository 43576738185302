.contact-home {
    .container {
      padding: 20px;
      height: 200px;
      margin-top: 40px;
    }
  
    .row {
      display: flex;
      justify-content: space-around;
    }
  
    .col-sm-4 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      
    }
  
    .social-icons {
      a {
        margin: 0 10px;
        color: #333;
        font-size: 24px;
        transition: color 0.3s;
  
        &:hover {
          color: #007bff;
        }
      }
    }
  
    .mobile-number, .location {
      font-size: 18px;
      display: flex;
      align-items: center;
  
      svg {
        margin-right: 10px;
        color: #007bff;
      }
    }
  }
  